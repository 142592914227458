import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth/authSlice";
import { authApi } from "./RTK/auth";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { satisfiedApi } from "./RTK/feedBack";
import { faqApi } from "./RTK/faq";
import { accountTypeApi } from "./RTK/accountType";
import { categoryeApi } from "./RTK/category";
import { PaymentApi } from "./RTK/payment";
import { subscriptionApi } from "./RTK/subscription";
import { nontificationApi } from "./RTK/nontification";
import { blogsApi } from "./RTK/blogs";
import { CouponApi } from "./RTK/copoun";
import { ColoringActivites } from "./RTK/coloringActivities";
import { WalletApi } from "./RTK/wallet";
import { categoriesProductApi } from "./RTK/categoriesProducts";
import { productApi } from "./RTK/products";
import { WalletProductApi } from "./RTK/walletPorducts";
import { OrderApi } from "./RTK/order";
import { DeliveryFeesApi } from "./RTK/deliveryFees";
import { DeliveryFeesHelpDataApi } from "./RTK/fethcDealerFeesData";
import { songsApi } from "./RTK/songs";
import { ParentGuides } from "./RTK/parentGuides";
import { UsersApi } from "./RTK/users";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [satisfiedApi.reducerPath]: satisfiedApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [categoryeApi.reducerPath]: categoryeApi.reducer,
    [accountTypeApi.reducerPath]: accountTypeApi.reducer,
    [PaymentApi.reducerPath]: PaymentApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [nontificationApi.reducerPath]: nontificationApi.reducer,
    [blogsApi.reducerPath]: blogsApi.reducer,
    [CouponApi.reducerPath]: CouponApi.reducer,
    [ColoringActivites.reducerPath]: ColoringActivites.reducer,
    [WalletApi.reducerPath]: WalletApi.reducer,
    [categoriesProductApi.reducerPath]: categoriesProductApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [WalletProductApi.reducerPath]: WalletProductApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [DeliveryFeesApi.reducerPath]: DeliveryFeesApi.reducer,
    [DeliveryFeesHelpDataApi.reducerPath]: DeliveryFeesHelpDataApi.reducer,
    [songsApi.reducerPath]: songsApi.reducer,
    [ParentGuides.reducerPath]: ParentGuides.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      satisfiedApi.middleware,
      faqApi.middleware,
      categoryeApi.middleware,
      accountTypeApi.middleware,
      PaymentApi.middleware,
      subscriptionApi.middleware,
      nontificationApi.middleware,
      blogsApi.middleware,
      CouponApi.middleware,
      ColoringActivites.middleware,
      WalletApi.middleware,
      categoriesProductApi.middleware,
      productApi.middleware,
      WalletProductApi.middleware,
      OrderApi.middleware,
      DeliveryFeesApi.middleware,
      DeliveryFeesHelpDataApi.middleware,
      songsApi.middleware,
      ParentGuides.middleware,
      UsersApi.middleware
    ),
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
