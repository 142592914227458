import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../helper/BaseQuery";

export const UsersApi = createApi({
  reducerPath: "UsersApi",
  baseQuery,
  tagTypes: ["users"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ page, search, register_day_from, register_day_to }) => ({
        url: "users",
        method: "get",
        params: {
          page,
          search,
          register_day_from,
          register_day_to,
        },
      }),
      providesTags: ["users"],
    }),
    exportUsers: builder.query({
      query: ({ search, register_day_from, register_day_to }) => ({
        url: `users/export-users`,
        method: "get",
        params: {
          search,
          register_day_from,
          register_day_to,
        },
      }),
      providesTags: ["users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyExportUsersQuery,
  useLazyGetUsersQuery,
} = UsersApi;
